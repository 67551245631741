<template>
  <div>
    <v-container fluid>
      <v-widget title="Estados e Cidades">
        <template v-slot:toolbar>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field filled flat single-line hide-details dense clearable solo v-model="busca" prepend-inner-icon="search" background-color="grey lighten-3" label="Busca" />
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:widget-content>
          <v-data-table :options.sync="options" :headers="headers" :items="estados" :search="busca" :loading="carregando" mobile-breakpoint="0">
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.nome }}</td>
                <td style="text-align: center">{{ item.sigla }}</td>
                <td style="text-align: center">{{ item.id }}</td>
                <td class="text-center">
                  <v-icon @click="exibirCidades(item)">list_alt</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-widget>
    </v-container>

    <dialog-lista-cidades ref="dialogCidades"></dialog-lista-cidades>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import constantes from '@/util/constantes';
import DialogListaCidades from './DialogListaCidades';

export default {
  name: 'ListaCidadesEstados',
  components: {
    DialogListaCidades,
  },

  data() {
    return {
      headers: [
        { text: 'Nome', value: 'nome' },
        { text: 'UF', value: 'sigla', width: '100px', align: 'center' },
        { text: 'Código', value: 'id', width: '100px', align: 'center' },
        { text: '', value: 'name', align: 'center', width: '32px', sortable: false },
      ],
      constantes: constantes,
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      estadoSelecionado: null,
    };
  },

  watch: {
    options: {
      handler() {
        this.carregar();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('cidadeEstado', {
      estados: 'estados',
      carregando: 'carregando',
    }),
  },

  methods: {
    carregar() {
      this.$store.dispatch('cidadeEstado/carregar');
    },

    async exibirCidades(estado) {
      try {
        await this.$store.dispatch('cidadeEstado/exibirCidades', estado);
        this.$refs.dialogCidades.abrir(estado);
      } catch (error) {
        this.$store.commit('setMensagemErro', error);
      }
    },
  },
};
</script>
