<template>
  <v-dialog v-model="dialog" max-width="900px" @keydown.esc="fechar" persistent scrollable>
    <v-card>
      <v-toolbar dark dense flat color="primary">
        <h3>{{ estado.nome }}</h3>
        <v-spacer />
        <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
      </v-toolbar>

      <v-toolbar dense flat color="white" class="my-2">
        <v-text-field filled flat single-line hide-details dense clearable solo v-model="busca" prepend-inner-icon="search" background-color="grey lighten-3" label="Busca"></v-text-field>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text style="height: 610px">
        <v-data-table :headers="headers" :items="cidades.filter((cidade) => cidade.estado === estado.sigla)" :search="busca" :loading="carregando">
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.cidade }}</td>
              <td>{{ props.item.codigo_cidade }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    valid: false,
    dialog: false,
    estado: '',
    busca: '',
    headers: [
      { text: 'Nome', value: 'cidade' },
      { text: 'Código', value: 'codigo_cidade', width: '100px' },
    ],
  }),

  computed: {
    ...mapState('cidadeEstado', {
      cidades: 'cidades',
      carregando: 'carregando',
    }),
  },

  methods: {
    abrir(estado) {
      this.estado = estado;
      this.dialog = true;

      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },

    fechar() {
      this.dialog = false;
      this.$emit('fechar');
    },
  },
};
</script>
